import { TelnyxRTC } from '@telnyx/webrtc';

const ReceiveCall = () => {
    setTimeout(() => {
        const client = new TelnyxRTC({
            login: 'support36348',
            password: 'avzEja6D',
            socketUrl: 'wss://rtc.telnyx.com:5061'
        });
        const answerCallButton = document.getElementById('answerCall');
        const forwardCallButton = document.getElementById('forwardCall');
        const remoteAudio = document.getElementById('remoteAudio');
        client.on('telnyx.socket.open', () => {
            console.log('Connected to Telnyx WebRTC');
        });
        client.on('telnyx.notification', (notification) => {
            if (notification.type === 'callUpdate' && notification.call.state === 'ringing') {
                console.log('Incoming call from:', notification.call.options.remoteCallerName);
                console.log('call:', notification.call);
                console.log('notification:', notification);
                answerCallButton.style.display = 'block';
                answerCallButton.onclick = () => {
                    notification.call.answer();
                    answerCallButton.style.display = 'none';
                };
                forwardCallButton.onclick = () => { 
                    forwardCall(notification.call); 
                    answerCallButton.style.display = 'none'; 
                    forwardCallButton.style.display = 'none';
               };
            }
            if (notification.type === 'callUpdate' && notification.call.state === 'active') {
                console.log('Call answered');
                remoteAudio.srcObject = notification.call.remoteStream;
            }
            if (notification.type === 'callUpdate' && notification.call.state === 'hangup') {
                console.log('Call ended');
                remoteAudio.srcObject = null;
            }
        });
        client.connect();
        function forwardCall(call) { 
            const forwardNumber = '+919033551647'; 
            call.forward(forwardNumber); 
            console.log(`Call forwarded to ${forwardNumber}`); 
        } 
    }, 2000);
    return (
        <>
            <h1>Telnyx WebRTC Example</h1>
            <button id="answerCall">Answer Call</button>
            <button id="forwardCall">Forward Call</button>
            <audio id="remoteAudio" autoPlay></audio>
        </>
    );
};
export default ReceiveCall;